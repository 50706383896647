let domain
if(STAGE == 'prod') {
  domain = 'https://matomete.solar'
}
else {
  domain = IS_LOCAL ? 'http://localhost:9000' : 'https://dev.matomete.solar'
}

export default {
  Auth: {
    oauth: {
      domain: 'auth.matomete.solar',
      scope: [
        'aws.cognito.signin.user.admin',
        'email',
        'openid',
        'profile'
      ],
      redirectSignIn: `${domain}/matomete-solar/`,
      redirectSignOut: `${domain}/matomete-solar/`,
      responseType: 'code'
    },
    region: AWS_DEFAULT_REGION, 
    userPoolId: COGNITO_USER_POOL_ID_SOLSEL,
    userPoolWebClientId: COGNITO_CLIENT_ID_SOLSEL, 
  }
}
